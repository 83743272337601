import { Node, mergeAttributes } from "@tiptap/core"
import { ReactNodeViewRenderer } from "@tiptap/react"
import StoreLinkPreviewComponent from "./component"

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    "store-link-preview": {
      setStoreLinkPreviewAlign: (options: "left" | "center" | "right") => ReturnType
    }
  }
}

export const StoreLinkPreview = Node.create({
  name: "store-link-preview",
  group: "block",
  content: "inline*",
  selectable: true,
  draggable: true,

  addAttributes() {
    return {
      title: {
        default: "",
      },
      description: {
        default: "",
      },
      url: {
        default: "",
      },
      image: {
        default: "",
      },
      position: {
        default: "left",
      },
    }
  },

  addCommands() {
    return {
      setStoreLinkPreviewAlign:
        (alignment: "left" | "center" | "right") =>
        ({ commands }) => {
          return commands.updateAttributes(this.name, { position: alignment })
        },
    }
  },

  parseHTML() {
    return [
      {
        tag: "store-link-preview",
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ["store-link-preview", mergeAttributes(HTMLAttributes)]
  },

  addNodeView() {
    return ReactNodeViewRenderer(StoreLinkPreviewComponent)
  },
})
