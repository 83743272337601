import { AdminProductDto } from "@runners/interface"
import { NodeViewWrapper } from "@tiptap/react"
import { CustomNodeViewType } from "../type"

interface AdminLinkPreviewProps {
  product: AdminProductDto
}

const AdminLinkPreviewComponent = (props: CustomNodeViewType<AdminLinkPreviewProps>) => {
  const { title, url, description, image } = props.node.attrs

  return (
    <NodeViewWrapper>
      <a data-drag-handle href={url} target="_blank">
        <div className="flex h-[100px] w-full items-center overflow-hidden rounded-2 border border-gray-300 md:h-[152px]">
          <div className="h-full w-[100px] shrink-0 bg-gray-300 md:w-52">
            {image && <img src={image} alt={title} className="size-full object-cover" />}
          </div>
          <div className="w-full overflow-hidden px-5 py-3">
            <div className="mb-1 truncate text-14 font-medium text-gray-800 md:text-16">{title}</div>
            <div className="mb-3 truncate text-12 text-gray-500 md:text-14">{description}</div>
            <div className="hidden truncate text-14 font-medium text-primary md:block">{url}</div>
          </div>
        </div>
      </a>
    </NodeViewWrapper>
  )
}

export default AdminLinkPreviewComponent
