import modalListStore, { ModalStackProps, useSyncModalStackStore } from "./modal-stack"

let key = 1

export const useModalStack = () => {
  const { push, pop, clear } = modalListStore
  const modalList = useSyncModalStackStore()

  const pushModal = <T>({ Component, componentProps, options }: Omit<ModalStackProps<T>, "id">) => {
    const id = `modal-${key++}`

    push({ id, Component, componentProps, options })
  }

  return { modalList, push: pushModal, clear, pop }
}
