import { ReactNode } from "react"
import { cn } from "@runners/lib/utils"
import { Button } from "@runners/ui"

interface ErrorAlertProps {
  title: string
  content: string | ReactNode
  className?: string
}

const ErrorAlert = (props: ErrorAlertProps & { close: () => Promise<void> }) => {
  const { close, title, content, className } = props
  return (
    <div
      className={cn("flex w-[314px] flex-col items-center gap-8 rounded-2 bg-white p-4 pt-6 shadow-popup", className)}
    >
      <div className="flex w-full flex-col gap-3">
        <span className="line-clamp-2 text-center text-18 font-bold text-gray-900">{title}</span>

        <div className="flex w-full flex-col gap-2 bg-gray-100 px-4 py-3">{content}</div>
      </div>

      <div className="flex w-full gap-2">
        <Button color="primary" size="40" className="h-10 flex-1 border-none" onClick={close}>
          확인
        </Button>
      </div>
    </div>
  )
}

export default ErrorAlert
