import { useForm, FormProvider, SubmitHandler } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import AdminCreateLayout from "@/_components/layout/admin-create-layout"
import NoticeForm from "../_components/admin-notice-form"
import { useStorePostNoticeMutation } from "../_hooks/use-mutation"

interface AdminNoticeForm {
  start_date: string
  end_date: string
  title: string
  content: string
  type: "GENERAL" | "PROMOTION" | ""
}

const AdminNoticeCreatePage = () => {
  const methods = useForm<AdminNoticeForm>({
    defaultValues: {
      type: "",
      start_date: "",
      end_date: "",
      title: "",
      content: "",
    },
  })

  const nav = useNavigate()
  const createPostMutation = useStorePostNoticeMutation({
    onSuccess: () => {
      nav(`/store/notice`, { replace: true })
    },
  })

  const onSubmit: SubmitHandler<AdminNoticeForm> = data => {
    const payload = {
      payload: {
        ...data,
        type: data.type as "GENERAL" | "PROMOTION",
      },
    }
    createPostMutation.mutate(payload)
  }

  return (
    <FormProvider {...methods}>
      <AdminCreateLayout onSubmit={methods.handleSubmit(onSubmit)}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <NoticeForm />
        </form>
      </AdminCreateLayout>
    </FormProvider>
  )
}

export default AdminNoticeCreatePage
