import { LinkPreviewDto } from "../../../interface/editor"
import AdminGuideComponent from "../_components/admin-guide"
import { AdminGuideTiptapContextProvider } from "../_context/admin-guide-tiptap-context"
import "../_css/editor.css"
interface AdminGuideEditorProps {
  initialValues?: string

  onChangeEditor?: (content: string) => void
  onLinkPreview?: () => Promise<LinkPreviewDto>
  onUploadVideo?: () => Promise<string[]>
  onStepTemplate?: () => Promise<{ stepNumber: string; title: string; content: string; image: string }>
}

const AdminGuideEditor = (props: AdminGuideEditorProps) => {
  const { initialValues, onLinkPreview, onChangeEditor, onStepTemplate, onUploadVideo } = props
  // if (!initialValues) return <div>Loading...</div>
  return (
    <AdminGuideTiptapContextProvider
      initialContent={initialValues}
      onLinkPreview={onLinkPreview}
      onChangeEditor={onChangeEditor}
      onUploadVideo={onUploadVideo}
      onStepTemplate={onStepTemplate}
    >
      <AdminGuideComponent />
    </AdminGuideTiptapContextProvider>
  )
}

export default AdminGuideEditor
