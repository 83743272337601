import { useSyncExternalStore } from "react"

export interface ModalStackProps<T> {
  id: string
  Component: (props: T & { close: () => Promise<void> }) => JSX.Element | null
  componentProps?: Omit<T, "close">
  options?: ModalOptions
}

export interface ModalOptions {
  disableBackdropClick?: boolean
  backdropTransparent?: boolean
  enabledEscClose?: boolean
  bottomDrawer?: boolean
}

let modalList: ModalStackProps<any>[] = []
let listeners: Array<() => void> = []

function emitChange() {
  for (const listener of listeners) {
    listener()
  }
}

const modalListStore = {
  push<T>(modal: ModalStackProps<T>) {
    modalList = [...modalList, modal]
    emitChange()
  },

  pop() {
    modalList = modalList.slice(0, -1)
    emitChange()
  },

  clear() {
    modalList = []
    emitChange()
  },

  subscribe(listener: () => void) {
    listeners = [...listeners, listener]
    return () => {
      listeners = listeners.filter(l => l !== listener)
    }
  },

  getSnapshot() {
    return modalList
  },
}

export function useSyncModalStackStore() {
  const { subscribe, getSnapshot } = modalListStore
  return useSyncExternalStore(subscribe, getSnapshot, getSnapshot)
}

export default modalListStore
