import { NodeViewWrapper } from "@tiptap/react"

interface AdminStepTemplateProps {
  stepNumber: string
  title: string
  content: string
  image: string
}

const StepTemplateComponent = ({ node }: { node: { attrs: AdminStepTemplateProps } }) => {
  const { stepNumber, title, content, image } = node.attrs

  return (
    <NodeViewWrapper>
      <div className="mb-6 flex">
        <div className="mx-auto font-pretendard text-14 text-[#9FA0AF]">{stepNumber}</div>
      </div>

      <div className="mb-4 flex">
        <h3 className="mx-auto font-pretendard text-28 font-bold text-[#424350]">{title}</h3>
      </div>

      <div className="mb-3 flex">
        <p className="mx-auto font-pretendard text-16 text-[#626270]">{content}</p>
      </div>

      <div>
        {image ? (
          <div className="h-[375px] w-full rounded-md bg-gray-100">
            <img src={image} alt={title} className="size-full rounded-6" />
          </div>
        ) : null}
      </div>
    </NodeViewWrapper>
  )
}

export default StepTemplateComponent
