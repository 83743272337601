import api from "."

// interface MediaImageResponse {
//   id: number
//   file: string
//   type: "image"
//   url: string
// }

/** ------------------------------------------------------------------------------
 * 
 * G. 미디어
 * 
 ------------------------------------------------------------------------------ */

const mediaApi = {
  /**
   * 파일 생성
   */
  postFile: async ({ formData }: { formData: FormData }) => {
    const { data } = await api(`/file`, {
      method: "POST",
      body: formData,
      headers: {
        "Content-Type": "application/json",
        // "Content-Type": "multipart/form-data",
      },
    })

    return data
  },
  /**
   * GIF 생성
   */
  postGif: async ({ formData }: { formData: FormData }) => {
    const { data } = await api(`/gif`, {
      method: "POST",
      body: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })

    return data
  },
  /**
   * 이미지 생성
   */
  postImage: async ({ formData }: { formData: FormData }) => {
    const { data } = await api(`/image`, {
      method: "POST",
      body: formData,
    })

    return data
  },

  /**
   * 비디오 생성
   */
  postVideo: async ({ formData }: { formData: FormData }) => {
    const { data } = await api(`/video`, {
      method: "POST",
      body: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })

    return data
  },
}

export default mediaApi
