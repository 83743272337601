import { Dispatch, PropsWithChildren, SetStateAction, useState } from "react"
import { AdminLinkPreview, AdminProductPreview, AdminCommentText, VideoPreview } from "@runners/editor-extensions"
import CodeBlockLowlight from "@tiptap/extension-code-block-lowlight"
import Color from "@tiptap/extension-color"
import Document from "@tiptap/extension-document"
import Gapcursor from "@tiptap/extension-gapcursor"
import Heading from "@tiptap/extension-heading"
import Highlight from "@tiptap/extension-highlight"
import Image from "@tiptap/extension-image"
import Link from "@tiptap/extension-link"
import ListItem from "@tiptap/extension-list-item"
import Paragraph from "@tiptap/extension-paragraph"
import Placeholder from "@tiptap/extension-placeholder"
import Text from "@tiptap/extension-text"
import TextAlign from "@tiptap/extension-text-align"
import TextStyle from "@tiptap/extension-text-style"
import Underline from "@tiptap/extension-underline"
import { Editor, useEditor } from "@tiptap/react"
import StarterKit from "@tiptap/starter-kit"
import { common, createLowlight } from "lowlight"
import { createDynamicContext } from "./create-dynamic-context"
import StepTemplate from "../../../../packages/editor-extensions/src/admin-guide-step/extension"
import { LinkPreviewDto } from "../../../interface/editor"
import { ImageResize } from "../_extensions/image-resize"

interface TiptapContextProps {
  editor: Editor
  htmlContent: string
  setHtmlContent: (value: string) => void

  // change 이벤트 싱크
  onChangeEditor?: (content: string) => void

  // 모달 콜백함수들
  onLinkPreview?: () => Promise<LinkPreviewDto>
  onUploadVideo?: () => Promise<string[]>
  onStepTemplate?: () => Promise<{
    stepNumber: string
    title: string
    content: string
    image: string
  }>

  isBlocking: boolean
  setIsBlocking: Dispatch<SetStateAction<boolean>>
}

const { ContextProvider, useContext } = createDynamicContext<TiptapContextProps>()

export const useAdminGuideTiptapContext = useContext

export interface AdminGuideTiptapContextProviderProps extends PropsWithChildren {
  initialContent?: string
  onChangeEditor?: (content: string) => void
  onLinkPreview?: () => Promise<LinkPreviewDto>
  onUploadVideo?: () => Promise<string[]>
  onStepTemplate?: () => Promise<{
    stepNumber: string
    title: string
    content: string
    image: string
  }>
}

export const AdminGuideTiptapContextProvider = (props: AdminGuideTiptapContextProviderProps) => {
  const { children, initialContent, onChangeEditor, onLinkPreview, onStepTemplate, onUploadVideo } = props

  const [htmlContent, setHtmlContent] = useState<string>(initialContent?.replace(/\\"/g, '"') || "")
  const [isBlocking, setIsBlocking] = useState(false)

  const lowlight = createLowlight(common)

  const editor = useEditor({
    extensions: [
      Placeholder.configure({ placeholder: "내용을 입력해주세요" }),
      Color.configure({ types: [TextStyle.name, ListItem.name] }),
      TextStyle.configure({ types: [ListItem.name] } as any),
      StarterKit.configure({
        bulletList: { keepMarks: true, keepAttributes: false },
        orderedList: { keepMarks: true, keepAttributes: false },
      }),
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      Heading.configure({ levels: [2, 3, 4, 5] }),
      Image.configure({ inline: true, allowBase64: true }),
      Underline.configure({ HTMLAttributes: { class: "my-custom-class" } }),
      CodeBlockLowlight.configure({ lowlight }),
      Highlight,
      Document,
      Paragraph,
      Text,
      Gapcursor,
      ImageResize,
      AdminProductPreview,
      AdminLinkPreview,
      AdminCommentText,
      Link.configure({ openOnClick: false, autolink: true }),
      StepTemplate,
      VideoPreview,
    ],
    editorProps: {
      attributes: {
        class: "focus:outline-none",
      },
    },
    content: initialContent,
    onUpdate: ({ editor }) => {
      const updatedContent = editor.getHTML()
      setHtmlContent(updatedContent)
      onChangeEditor?.(updatedContent)
    },
  })

  if (!editor) return null

  return (
    <ContextProvider
      editor={editor}
      htmlContent={htmlContent}
      setHtmlContent={setHtmlContent}
      onLinkPreview={onLinkPreview}
      onChangeEditor={onChangeEditor}
      onUploadVideo={onUploadVideo}
      onStepTemplate={onStepTemplate}
      isBlocking={isBlocking}
      setIsBlocking={setIsBlocking}
    >
      {children}
    </ContextProvider>
  )
}
