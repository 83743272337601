import { PropsWithChildren, useState } from "react"
import CodeBlockLowlight from "@tiptap/extension-code-block-lowlight"
import Color from "@tiptap/extension-color"
import Document from "@tiptap/extension-document"
import Gapcursor from "@tiptap/extension-gapcursor"
import Highlight from "@tiptap/extension-highlight"
import Image from "@tiptap/extension-image"
import Link from "@tiptap/extension-link"
import ListItem from "@tiptap/extension-list-item"
import Paragraph from "@tiptap/extension-paragraph"
import Placeholder from "@tiptap/extension-placeholder"
import Text from "@tiptap/extension-text"
import TextAlign from "@tiptap/extension-text-align"
import TextStyle from "@tiptap/extension-text-style"
import Underline from "@tiptap/extension-underline"
import { Editor, useEditor } from "@tiptap/react"
import StarterKit from "@tiptap/starter-kit"
import { common, createLowlight } from "lowlight"
import { createDynamicContext } from "./create-dynamic-context"
import type { DropdownItemType, TiptapFunctionalProps } from "../_editor/story-editor"
import { ImageResize } from "../_extensions/image-resize"

interface TiptapContextProps {
  editor: Editor
  content: string
  setContent: (content: string) => void
  editorText: string
  setEditorText: (editorText: string) => void
  htmlContent: string
  setHtmlContent: (htmlContent: string) => void

  isTitle?: boolean
  isDropdown?: boolean
  dropdownList?: DropdownItemType[]
  title: string
  setTitle: (title: string) => void
  dropdown: DropdownItemType | null
  setDropdown: (dropdown: DropdownItemType) => void

  onSubmit: () => void
  onClose?: () => void
  onMyEventClick?: () => void
}

const { ContextProvider, useContext } = createDynamicContext<TiptapContextProps>()

export const useTiptapContext = useContext

export interface TiptapContextProviderProps extends PropsWithChildren, TiptapFunctionalProps {}

export const TiptapContextProvider = (props: TiptapContextProviderProps) => {
  const {
    children,
    addExtensions = [],
    onSubmit,
    onClose,
    onMyEventClick,
    isTitle,

    initialDropdown,
    initialTitle,
    initialContent,

    // isDropdown이 false라면 dropdownList도 사용하지 않습니다.
    isDropdown,
    dropdownList,

    placeholder,
  } = props

  const [content, setContent] = useState<string>(initialContent || "")
  const [editorText, setEditorText] = useState<string>("")
  const [htmlContent, setHtmlContent] = useState<string>("")

  // title
  const [title, setTitle] = useState<string>(initialTitle || "")

  // dropdown (type)
  const [dropdown, setDropdown] = useState<DropdownItemType | null>(initialDropdown || null)

  const lowlight = createLowlight(common)

  const editor = useEditor({
    extensions: [
      Placeholder.configure({
        placeholder:
          placeholder ||
          "자유롭게 의견을 적어주세요.\n\n스토리 작성하실때 이점 유의해주세요!\n1. 권리침해, 욕설, 비하, 명예훼손, 혐오, 불법촬영물 등의 내용을 게시하면 운영정책 및 관련 법률에 의해 제재될 수 있습니다.\n2. 본인이 쓴 게시글 및 댓글에 대한 법적 책임은 본인에게 있습니다.",
      }),
      Color.configure({ types: [TextStyle.name, ListItem.name] }),
      TextStyle.configure({ types: [ListItem.name] } as any),
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false,
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false,
        },
      }),
      TextAlign.configure({
        types: ["heading", "paragraph"],
      }),
      Image.configure({
        inline: true,
        allowBase64: true,
      }),
      Underline.configure({
        HTMLAttributes: {
          class: "my-custom-class",
        },
      }),
      CodeBlockLowlight.configure({
        lowlight,
      }),
      Highlight,
      Document,
      Paragraph,
      Text,
      Gapcursor,
      ImageResize,
      Link.configure({
        openOnClick: false,
        autolink: true,
      }),
      ...addExtensions,
    ],
    editorProps: {
      attributes: {
        class: "m-2 focus:outline-none",
      },
    },
    content,
  })

  const handleSubmit = () => {
    if (!editor) return

    let payload = {
      content: editor.getHTML(),
    } as any

    if (isTitle) {
      payload.title = title
    }

    if (isDropdown) {
      payload.type = dropdown?.value
    }

    onSubmit(payload)
  }

  if (!editor) return

  return (
    <ContextProvider
      content={content}
      setContent={setContent}
      editorText={editorText}
      setEditorText={setEditorText}
      htmlContent={htmlContent}
      setHtmlContent={setHtmlContent}
      editor={editor}
      onSubmit={handleSubmit}
      onClose={onClose}
      onMyEventClick={onMyEventClick}
      // 타이틀
      isTitle={isTitle}
      title={title}
      setTitle={setTitle}
      // 주제 선택
      dropdown={dropdown}
      setDropdown={setDropdown}
      isDropdown={isDropdown}
      dropdownList={dropdownList}
    >
      {children}
    </ContextProvider>
  )
}
