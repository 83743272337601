import { ChangeEvent, Dispatch, SetStateAction, useRef } from "react"
import { cn } from "@runners/lib/utils"
import { Editor } from "@tiptap/react"
import mediaApi from "../_api/media"
import { Capture } from "../icons"
import ErrorAlert from "./modal/error"
import ExceedSizeModal from "./modal/exceed-size"
import { useModalStack } from "./modal/use-modal-stack"

interface TopMenuImageUploaderProps {
  editor: Editor
  multiple?: boolean

  isLoading?: boolean
  setIsLoading?: Dispatch<SetStateAction<boolean>>
}

const TWENTY_MB = 20 * 1024 * 1024

const TopMenuImageUpload = (props: TopMenuImageUploaderProps) => {
  const { editor, multiple = false, isLoading = false, setIsLoading = () => {} } = props
  const fileInputRef = useRef<HTMLInputElement>(null)

  const modalStack = useModalStack()

  if (!editor) return

  const handleImageChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return

    const file = e.target.files[0]

    if (file.size > TWENTY_MB) {
      modalStack.push({
        Component: ExceedSizeModal,
        componentProps: {
          exceededFile: [file],
        },
        options: {
          enabledEscClose: true,
        },
      })
      return
    }

    setIsLoading(true)
    const formData = new FormData()
    formData.append("file", file)

    try {
      const result = await mediaApi.postImage({ formData })
      editor.commands.enter()
      editor.chain().setImage({ src: result.url }).run()
      editor.commands.enter()
    } catch (e: any) {
      const { message, details } = e

      modalStack.push({
        Component: ErrorAlert,
        componentProps: {
          title: message || "업로드에 실패했어요.",
          content: details?.file || "서버 문제일 수 있어요. 잠시후 다시 시도해주세요.",
        },
      })
    }

    e.target.value = ""
    setIsLoading(false)
  }

  const handleImageMultiple = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return

    const files = Array.from(e.target.files)
    if (!files.length) return

    /**
     * 용량 체크
     */
    const exceededSize = []
    for (const file of files) {
      if (file.size > TWENTY_MB) {
        exceededSize.push(file)
      }
    }

    if (exceededSize.length > 0) {
      modalStack.push({
        Component: ExceedSizeModal,
        componentProps: {
          exceededFile: exceededSize,
        },
        options: {
          enabledEscClose: true,
        },
      })

      e.target.value = ""
      return
    }

    setIsLoading(true)
    let imageUrls = []

    try {
      for (const file of files) {
        const formData = new FormData()
        formData.append("file", file)
        const result = await mediaApi.postImage({ formData })
        imageUrls.push(result.url)
      }
      editor.commands.enter()
      editor
        .chain()
        .focus()
        .insertContent(
          imageUrls.map(v => ({
            type: "image",
            attrs: {
              src: v,
            },
          })),
        )
        .run()
      editor.commands.enter()
    } catch (e: any) {
      const { message, details } = e

      modalStack.push({
        Component: ErrorAlert,
        componentProps: {
          title: message || "업로드에 실패했어요.",
          content: details?.file || "서버 문제일 수 있어요. 잠시후 다시 시도해주세요.",
        },
      })
    }

    e.target.value = ""
    setIsLoading(false)
  }

  const handleIconClick = () => {
    fileInputRef.current?.click()
  }

  return (
    <div
      className={cn("flex cursor-pointer flex-col items-center justify-center px-1.5 md:hover:bg-gray-200", {
        "pointer-events-none opacity-50": isLoading,
      })}
      onClick={handleIconClick}
    >
      <input
        ref={fileInputRef}
        type="file"
        multiple={multiple}
        className="hidden"
        onChange={multiple ? handleImageMultiple : handleImageChange}
        accept="image/*"
      />

      <div className={cn("p-1", {})}>
        <Capture className={cn("size-6")} />
      </div>

      <p className="text-12 text-gray-700">사진</p>
    </div>
  )
}

export default TopMenuImageUpload
