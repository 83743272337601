import { mergeAttributes, Node } from "@tiptap/core"
import { ReactNodeViewRenderer } from "@tiptap/react"
import AdminProductPreviewComponent from "./component"

export const AdminProductPreview = Node.create({
  name: "contents-product-preview",
  group: "block",
  selectable: true,
  draggable: true,

  addAttributes() {
    return {
      id: {
        default: null,
      },
      title: {
        default: "",
      },
      store_title: {
        default: "",
      },
      discount_rate: {
        default: null,
      },
      discount_price: {
        default: null,
      },
      like_count: {
        default: null,
      },
      average_grade: {
        default: null,
      },
      description: {
        default: "",
      },
      image_url: {
        default: "",
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: "contents-product-preview",
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ["contents-product-preview", mergeAttributes(HTMLAttributes)]
  },

  addNodeView() {
    return ReactNodeViewRenderer(AdminProductPreviewComponent)
  },
})
