import { cn } from "@runners/lib/utils"
import { Button } from "@runners/ui"

interface ExceedSizeModalProps {
  className?: string
  exceededFile: File[]
}

const ExceedSizeModal = (props: ExceedSizeModalProps & { close: () => Promise<void> }) => {
  const { className, exceededFile, close } = props

  return (
    <div
      className={cn("flex w-[314px] flex-col items-center gap-8 rounded-2 bg-white p-4 pt-6 shadow-popup", className)}
    >
      <div className="flex w-full flex-col gap-3">
        <span className="line-clamp-2 text-center text-18 font-bold text-gray-900">
          {exceededFile.length}개의 파일이
          <br />
          용량을 넘었어요.
        </span>

        <div className="flex w-full flex-col gap-2 bg-gray-100 px-4 py-3">
          {exceededFile.map(v => (
            <div key={v.name} className="flex w-full items-center gap-2">
              <p className="line-clamp-1 w-full text-ellipsis text-14 text-gray-700">{v.name}</p>
              <p className="shrink-0 text-12 text-primary">용량초과</p>
            </div>
          ))}
        </div>
      </div>

      <div className="flex w-full gap-2">
        <Button color="primary" size="40" className="h-10 flex-1 border-none" onClick={close}>
          확인
        </Button>
      </div>
    </div>
  )
}

export default ExceedSizeModal
