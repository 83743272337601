import { cn } from "@runners/lib/utils"
import { Image } from "@runners/ui"
import { NodeViewWrapper } from "@tiptap/react"
import { CustomNodeViewType } from "../type"

interface StoreLinkPreviewProps {
  title: string
  url: string
  description: string
  image: string
  position: "left" | "center" | "right"
}

const StoreLinkPreviewComponent = (props: CustomNodeViewType<StoreLinkPreviewProps>) => {
  const { node, editor, getPos } = props
  const { title, url, description, image, position } = node.attrs

  const handleClick = () => {
    if (!editor.isEditable) return
    editor.commands.setNodeSelection(getPos())
  }

  return (
    <NodeViewWrapper>
      <div
        className={cn("flex w-full select-none", {
          "justify-start": position === "left",
          "justify-center": position === "center",
          "justify-end": position === "right",
        })}
      >
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className="store-link-preview relative block w-fit"
          onClick={handleClick}
        >
          <div
            data-drag-handle
            className="block w-full max-w-[450px] rounded-1 border border-gray-300 md:flex md:max-w-[640px] md:gap-5"
            contentEditable={false}
          >
            <Image
              src={image}
              size="1280"
              className="h-[270px] w-[450px] rounded-t-1 md:h-[120px] md:max-w-[202px] md:rounded-l-1"
            />

            <div className="hidden md:block" contentEditable={false}>
              <div className="flex grow-0 flex-col justify-between py-4">
                <div>
                  <p className="line-clamp-1 font-medium text-black">{title}</p>
                  <p className="mt-1 line-clamp-1 text-gray-600">{description}</p>
                </div>
                <div>
                  <p className="mt-3 line-clamp-1 text-primary-500">{url}</p>
                </div>
              </div>
            </div>

            <div className="px-5 py-4 md:hidden" contentEditable={false}>
              <p className="text-16 font-medium">{title}</p>
              <p className="mt-1 line-clamp-1 text-ellipsis text-14 text-gray-500">{description}</p>
              <p className="mt-3 text-14 font-medium text-primary">{url}</p>
            </div>
          </div>
        </a>
      </div>
    </NodeViewWrapper>
  )
}

export default StoreLinkPreviewComponent
