import { cn } from "@runners/lib/utils"
import { NodeViewWrapper } from "@tiptap/react"
import { CustomNodeViewType } from "../type"

const VideoPreviewComponent = (props: CustomNodeViewType<{ src: string }>) => {
  const { node, getPos, editor } = props
  const { src, position } = node.attrs

  const handleClick = () => {
    if (!editor.isEditable) return
    editor.commands.setNodeSelection(getPos())
  }

  return (
    // eslint-disable-next-line tailwindcss/no-custom-classname
    <NodeViewWrapper className="video-preview">
      <div
        className={cn("inline-flex w-full select-none flex-col gap-4", {
          "justify-start": position === "left",
          "justify-center": position === "center",
          "justify-end": position === "right",
        })}
        onClick={handleClick}
      >
        <video controls className="max-w-full rounded border border-gray-400">
          <source src={src} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </NodeViewWrapper>
  )
}

export default VideoPreviewComponent
