import { cn } from "@runners/lib/utils"
import { Editor } from "@tiptap/react"
import { useTiptapContext } from "../_context/tiptap-context"
import { Bold, Center, Italic, Left, Redo, Right, Strike, Underline, Undo } from "../icons"

interface DefaultMenuBarProps {}

type alignCommandsHandlerProps = {
  editor: Editor
  alignment: "left" | "center" | "right"
}

type alignCommandsHandlerFunction = (props: alignCommandsHandlerProps) => void

const alignCommandsHandler: { [key: string]: alignCommandsHandlerFunction } = {
  "store-link-preview": ({ editor, alignment }) => editor.chain().focus().setStoreLinkPreviewAlign(alignment).run(),
  "video-preview": ({ editor, alignment }) => editor.chain().focus().setVideoPreviewAlign(alignment).run(),
  youtube: ({ editor, alignment }) => editor.chain().focus().setYoutubeAlign(alignment).run(),
}

const DefaultMenuBar = (props: DefaultMenuBarProps) => {
  const {} = props

  const { editor } = useTiptapContext()

  const MenuBarIconValue = getMenuBarIcon({ editor })

  return (
    <div className="mx-auto flex w-full gap-1.5">
      {MenuBarIconValue.map(item => {
        return (
          <div key={item.id} className="flex h-full items-center gap-1">
            <button
              onClick={item.onClick}
              disabled={item.disable}
              className={cn({
                "cursor-not-allowed": item.disable,
                "cursor-pointer p-1": !item.disable,
                "bg-gray-500": item.isActive,
                "hover:bg-gray-300": !item.isActive,
              })}
              // className={`${
              //   item.disable ? "cursor-not-allowed" : "cursor-pointer p-1"
              // } ${item.isActive ? "bg-gray-500" : "hover:bg-gray-300"}`}
            >
              {item.icon}
            </button>

            {item.split && <div className="mx-2.5 flex h-4 w-px bg-gray-300" />}
          </div>
        )
      })}
    </div>
  )
}

export default DefaultMenuBar

export const getMenuBarIcon = ({ editor, heading, undo }: { editor: Editor; heading?: boolean; undo?: boolean }) => {
  const customName = (editor.view.state.selection as any)?.node?.type?.name

  /**
   * 스토리 에디터에서 사용되는 디폴트 기본 아이콘
   */
  const defaultIcons = [
    {
      id: 1,
      name: "bold",
      icon: <Bold />,
      onClick: () => editor.chain().focus().toggleBold().run(),
      disable: !editor.can().chain().focus().toggleBold().run(),
      isActive: editor.isActive("bold") ? "is-active" : "",
      hover: false,
      split: false,
    },
    {
      id: 2,
      name: "italic",
      icon: <Italic />,
      onClick: () => editor.chain().focus().toggleItalic().run(),
      disable: !editor.can().chain().focus().toggleItalic().run(),
      isActive: editor.isActive("italic") ? "is-active" : "",
      hover: false,
      split: false,
    },
    {
      id: 21,
      name: "underline",
      icon: <Underline />,
      onClick: () => editor.chain().focus().toggleUnderline().run(),
      disable: !editor.can().chain().focus().toggleItalic().run(),
      isActive: editor.isActive("underline") ? "is-active" : "",
      hover: false,
      split: false,
    },
    {
      id: 3,
      name: "strike",
      icon: <Strike />,
      onClick: () => editor.chain().focus().toggleStrike().run(),
      disable: !editor.can().chain().focus().toggleStrike().run(),
      isActive: editor.isActive("strike") ? "is-active" : "",
      hover: false,
      split: true,
    },
    {
      id: 16,
      name: "align left",
      icon: <Left />,
      onClick: () => {
        if (customName) {
          alignCommandsHandler[customName]?.({ editor, alignment: "left" })
        } else {
          editor.chain().focus().setTextAlign("left").run()
        }
      },
      disable: false,
      isActive: false,
      hover: false,
      split: false,
    },
    {
      id: 17,
      name: "align center",
      icon: <Center />,
      onClick: () => {
        if (customName) {
          alignCommandsHandler[customName]?.({ editor, alignment: "center" })
        } else {
          editor.chain().focus().setTextAlign("center").run()
        }
      },
      disable: false,
      isActive: customName
        ? editor.getAttributes(customName).position === "center"
        : editor.isActive({ textAlign: "center" }),
      hover: false,
      split: false,
    },
    {
      id: 18,
      name: "align right",
      icon: <Right />,
      onClick: () => {
        if (customName) {
          alignCommandsHandler[customName]?.({ editor, alignment: "right" })
        } else {
          editor.chain().focus().setTextAlign("right").run()
        }
      },
      disable: false,
      isActive: customName
        ? editor.getAttributes(customName).position === "right"
        : editor.isActive({ textAlign: "right" }),
      hover: false,
      split: false,
    },
  ]

  if (!heading && !undo) return defaultIcons

  if (heading) {
    const headingIcons = [
      {
        id: 91,
        name: "heading 2",
        icon: <div className="rounded-2">H2</div>,
        onClick: () => editor.chain().focus().toggleHeading({ level: 2 }).run(),
        disable: false,
        isActive: editor.isActive("heading", { level: 2 }) ? "is-active" : "",
        hover: false,
        split: false,
      },
      {
        id: 92,
        name: "heading 3",
        icon: <div className="rounded-2">H3</div>,
        onClick: () => editor.chain().focus().toggleHeading({ level: 3 }).run(),
        disable: false,
        isActive: editor.isActive("heading", { level: 3 }) ? "is-active" : "",
        hover: false,
        split: false,
      },
      {
        id: 93,
        name: "heading 4",
        icon: <div className="rounded-2">H4</div>,
        onClick: () => editor.chain().focus().toggleHeading({ level: 4 }).run(),
        disable: false,
        isActive: editor.isActive("heading", { level: 4 }) ? "is-active" : "",
        hover: false,
        split: false,
      },
      {
        id: 94,
        name: "heading 5",
        icon: <div className="rounded-2">H5</div>,
        onClick: () => editor.chain().focus().toggleHeading({ level: 5 }).run(),
        disable: false,
        isActive: editor.isActive("heading", { level: 5 }) ? "is-active" : "",
        hover: false,
        split: true,
      },
    ]

    defaultIcons.push(...headingIcons)
  }

  if (undo) {
    const undoIcons = [
      {
        id: 30,
        name: "undo",
        icon: <Undo className="size-6" />,
        onClick: () => editor.chain().focus().undo().run(),
        disable: !editor.can().undo(),
        isActive: editor.isActive("table") ? "is-active text-green-700" : "",
        hover: false,
        split: false,
      },
      {
        id: 31,
        name: "redo",
        icon: <Redo className="size-6" />,
        onClick: () => editor.chain().focus().redo().run(),
        disable: !editor.can().redo(),
        isActive: editor.isActive("table") ? "is-active text-green-700" : "",
        hover: false,
        split: true,
      },
    ]

    defaultIcons.push(...undoIcons)
  }

  return defaultIcons
}
