import { useFormContext } from "react-hook-form"
import { useModalStack } from "@/_zustand/admin-modal-stack"
import AdminGuideContents from "./fields/admin-guide-contents"
import AdminGuideMobileThumbnail from "./fields/admin-guide-mobile-thumbnail"
import AdminGuideThumbnail from "./fields/admin-guide-thumbnail"
import AdminGuideTitle from "./fields/admin-guide-title"
import StepModal from "./modal/admin-guide-step-modal"

const GuideTipForm = ({ guideDetail }: { guideDetail?: { content: string } }) => {
  const modalStack = useModalStack()
  const { setValue } = useFormContext()

  const onStepTemplate = (): Promise<{ stepNumber: string; title: string; content: string; image: string }> => {
    return new Promise(resolve => {
      modalStack.open({
        Component: StepModal,
        componentProps: {
          onSubmit: (data: { stepNumber: string; title: string; content: string; image: string }) => {
            resolve(data)
          },
        },
        key: "스텝 추가 모달",
        disableBackdropClick: true,
      })
    })
  }
  const handleEditorChange = (editorContent: string) => {
    setValue("content", editorContent)
  }

  return (
    <div className="max-w-[1200px]">
      <AdminGuideThumbnail />
      <AdminGuideMobileThumbnail />
      <AdminGuideTitle />
      <AdminGuideContents
        onStepTemplate={onStepTemplate}
        initialContent={guideDetail?.content}
        onChangeEditor={handleEditorChange}
      />
    </div>
  )
}

export default GuideTipForm
