"use server"

const BASE_URL =
  typeof import.meta.env !== "undefined" && import.meta.env.VITE_API_URL
    ? import.meta.env.VITE_API_URL
    : (typeof process !== "undefined" && process.env.NEXT_PUBLIC_BASE_API_URL) || ""

const api = async (url: string, options: any) => {
  const result = await fetch(`${BASE_URL}/v1${url}`, {
    body: options.body,
    ...options,
  })

  if (!result.ok) {
    const errorData = await result.json()
    return Promise.reject(errorData)
  }

  const toJson = await result.json()
  return toJson
}

export default api
