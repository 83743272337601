import { mergeAttributes, Node } from "@tiptap/core"
import { ReactNodeViewRenderer } from "@tiptap/react"
import AdminCommentTextComponent from "./component"

export const AdminCommentText = Node.create({
  name: "contents-comment-text",
  group: "block",
  selectable: true,
  draggable: true,

  addAttributes() {
    return {
      title: {
        default: "",
      },
      comment: {
        default: "",
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: "contents-comment-text",
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ["contents-comment-text", mergeAttributes(HTMLAttributes)]
  },

  addNodeView() {
    return ReactNodeViewRenderer(AdminCommentTextComponent)
  },
})
