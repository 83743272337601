import { mergeAttributes, Node } from "@tiptap/core"
import { ReactNodeViewRenderer } from "@tiptap/react"
import AdminLinkPreviewComponent from "./component"

export const AdminLinkPreview = Node.create({
  name: "contents-link-preview",
  group: "block",
  selectable: true,
  draggable: true,

  addAttributes() {
    return {
      title: {
        default: "",
      },
      description: {
        default: "",
      },
      url: {
        default: "",
      },
      image: {
        default: "",
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: "contents-link-preview",
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ["contents-link-preview", mergeAttributes(HTMLAttributes)]
  },

  addNodeView() {
    return ReactNodeViewRenderer(AdminLinkPreviewComponent)
  },
})
