import { mergeAttributes, Node } from "@tiptap/core"
import { ReactNodeViewRenderer } from "@tiptap/react"
import VideoPreviewComponent from "./component"

export interface VideoPreviewType {
  src: string
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    "video-preview": {
      setVideoPreviewAlign: (options: "left" | "center" | "right") => ReturnType
    }
  }
}

export const VideoPreview = Node.create({
  name: "video-preview",
  group: "block",
  draggable: true,
  selectable: true,

  addAttributes() {
    return {
      src: {
        default: "",
      },
      position: {
        default: "left",
      },
    }
  },

  addCommands() {
    return {
      setVideoPreviewAlign:
        (alignment: "left" | "center" | "right") =>
        ({ commands }) => {
          return commands.updateAttributes(this.name, { position: alignment })
        },
    }
  },

  parseHTML() {
    return [
      {
        tag: "div[data-video-preview]",
        getAttrs: element => {
          const videoElement = element.querySelector("video source")
          const src = videoElement ? (videoElement as HTMLSourceElement).src : null
          return src ? { src } : false
        },
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    const src: string = HTMLAttributes.src

    return [
      "div",
      mergeAttributes({ "data-video-preview": "" }, this.options.HTMLAttributes),
      [
        "video",
        {
          controls: true,
          class: "max-w-full rounded border border-gray-400",
        },
        ["source", { src: src, type: "video/mp4" }],
        "Your browser does not support the video tag.",
      ],
    ]
  },

  addNodeView() {
    return ReactNodeViewRenderer(VideoPreviewComponent)
  },
})
