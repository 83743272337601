import { EditorContent } from "@tiptap/react"
import { useAdminGuideTiptapContext } from "../_context/admin-guide-tiptap-context"
import { Link, SignPost, Video } from "../icons"
import { getMenuBarIcon } from "./default-menu-bar"
import TopMenuImageUpload from "./top-menu-image-uploader"
const AdminGuideComponent = () => {
  const { editor, onLinkPreview, onStepTemplate, onUploadVideo, isBlocking, setIsBlocking } =
    useAdminGuideTiptapContext()
  const MenuBarIconValue = getMenuBarIcon({ editor, undo: true, heading: true })

  const handleLinkClick = async () => {
    if (!onLinkPreview) return

    try {
      const data = await onLinkPreview()
      editor.commands.enter()
      editor
        .chain()
        .focus()
        .insertContent({
          type: "contents-link-preview",
          attrs: data,
        })
        .run()
      editor.commands.enter()
    } catch (error) {
      console.error("링크 프리뷰를 삽입하는 중에 오류가 발생했습니다:", error)
    }
  }

  const handleUploadClick = async () => {
    if (!onUploadVideo) return

    try {
      const uploadedVideos = await onUploadVideo()

      if (!uploadedVideos || uploadedVideos.length === 0) {
        console.warn("업로드된 동영상이 없습니다.")
        return
      }

      // 업로드된 동영상 삽입
      uploadedVideos.forEach(videoUrl => {
        editor
          .chain()
          .focus()
          .insertContent({
            type: "video-preview",
            attrs: {
              src: videoUrl,
            },
          })
          .run()
      })

      editor.commands.enter()
    } catch (error) {
      console.error("동영상을 추가하는 중에 오류가 발생했습니다:", error)
    }
  }

  const handleStepClick = async () => {
    if (!onStepTemplate) return

    try {
      const data = await onStepTemplate()

      editor
        .chain()
        .focus()
        .insertContent({
          type: "guide-step-template",
          attrs: {
            stepNumber: data.stepNumber || "스텝 번호를 입력하세요",
            title: data.title || "스텝 제목을 입력하세요",
            content: data.content || "스텝 내용을 입력하세요",
            image: data.image || "",
          },
        })
        .run()

      editor.commands.enter()
    } catch (error) {
      console.error("스텝 템플릿을 추가하는 중에 오류가 발생했습니다:", error)
    }
  }

  return (
    <div className="mt-5 flex gap-5">
      <div className="flex w-[240px] shrink-0 text-14 font-bold">
        <div className="text-14 font-bold">내용</div>
        <span className="ml-0.5 text-16 font-bold text-primary-800">*</span>
      </div>
      <div className="min-h-[500px] min-w-[1080px] border border-gray-300 p-5">
        <div className="border-b border-gray-300">
          <div className="mx-auto mb-4 flex w-full gap-1.5">
            <TopMenuImageUpload editor={editor} multiple isLoading={isBlocking} setIsLoading={setIsBlocking} />

            <button type="button" onClick={handleUploadClick} className="px-1.5 md:hover:bg-gray-200">
              <div className="p-1">
                <Video className="size-6 text-gray-700" />
              </div>
              <p className="text-12 text-gray-700">동영상</p>
            </button>
            <button type="button" onClick={handleLinkClick} className="px-1.5 md:hover:bg-gray-200">
              <div className="p-1">
                <Link className="size-6 text-gray-700" />
              </div>
              <p className="text-12 text-gray-700">링크</p>
            </button>
            {onStepTemplate && (
              <button type="button" onClick={handleStepClick} className="px-1.5 md:hover:bg-gray-200">
                <div className="p-1">
                  <SignPost className="size-6 text-gray-500" />
                </div>
                <p className="text-12 text-gray-700">스텝</p>
              </button>
            )}
          </div>

          <div className="mx-auto flex w-full gap-1.5">
            {MenuBarIconValue.map(item => {
              return (
                <div key={item.id} className="flex h-full items-center gap-1">
                  <button
                    onClick={item.onClick}
                    disabled={item.disable}
                    className={`${
                      item.disable ? "cursor-not-allowed p-1" : "cursor-pointer rounded-lg p-1"
                    } ${item.isActive ? "bg-gray-500" : "hover:bg-gray-300"}`}
                    type="button"
                  >
                    {item.icon}
                  </button>

                  {item.split && <div className="mx-2.5 flex h-4 w-px bg-gray-300" />}
                </div>
              )
            })}
          </div>
        </div>
        <div className="h-10" />
        <EditorContent editor={editor} />
      </div>
    </div>
  )
}

export default AdminGuideComponent
