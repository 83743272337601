import { useForm, FormProvider, SubmitHandler } from "react-hook-form"
import { useNavigate, useSearchParams } from "react-router-dom"
import AdminCreateLayout from "@/_components/layout/admin-create-layout"
import GuideFaqForm from "../_components/admin-guide-faq-form"
import GuideTipForm from "../_components/admin-guide-tip-form"
import { useStorePostGuideMutation } from "../_hooks/use-mutation"

interface AdminGuideForm {
  thumbnail_image_url?: string | null
  mobile_thumbnail_image_url?: string | null
  title: string
  content: string
  sub_type: string
}

interface PostAdminGuidePayload {
  payload: AdminGuideForm & {
    type: "TIP" | "FAQ"
  }
}

const AdminGuideCreatePage = () => {
  const [searchParams] = useSearchParams()

  const type = searchParams.get("type") as "TIP" | "FAQ"

  const methods = useForm<AdminGuideForm>({
    defaultValues: {
      thumbnail_image_url: null,
      mobile_thumbnail_image_url: null,
      title: "",
      content: "",
      sub_type: "",
    },
  })

  const nav = useNavigate()
  const createPostMutation = useStorePostGuideMutation({
    onSuccess: () => {
      nav(`/store/guide`, { replace: true })
    },
  })

  const onSubmit: SubmitHandler<AdminGuideForm> = data => {
    const payload: PostAdminGuidePayload = {
      payload: {
        ...data,
        type,
      },
    }
    createPostMutation.mutate(payload)
  }

  return (
    <FormProvider {...methods}>
      <AdminCreateLayout onSubmit={methods.handleSubmit(onSubmit)}>
        <div>
          {type === "TIP" && (
            <>
              <h2>판매 활용 Tip 생성</h2>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <GuideTipForm />
              </form>
            </>
          )}
          {type === "FAQ" && (
            <>
              <h2>FAQ 생성</h2>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <GuideFaqForm />
              </form>
            </>
          )}
        </div>
      </AdminCreateLayout>
    </FormProvider>
  )
}

export default AdminGuideCreatePage
