import React from "react"
import { NodeViewWrapper } from "@tiptap/react"
import { SummerSign } from "../icons"
import { CustomNodeViewType } from "../type"

interface AdminCommentTextProps {
  title: string
  comment: string
}

const AdminCommentTextComponent = (props: CustomNodeViewType<AdminCommentTextProps>) => {
  const { title, comment } = props.node.attrs

  return (
    <NodeViewWrapper>
      <div data-drag-handle className="w-full rounded-3 bg-gray-100 p-8">
        <div className="mb-2 text-20 font-bold text-gray-900">{title}</div>

        <div className="mb-3 w-full text-14 text-gray-700">
          {(comment.split("\n") || []).map((v, i) => {
            return (
              <React.Fragment key={`${v}_index_${i}`}>
                {v}
                <br />
              </React.Fragment>
            )
          })}
        </div>

        <div className="flex w-full items-center justify-end gap-1.5">
          <span className="text-14 font-bold text-gray-800">by</span>
          <SummerSign className="h-auto w-[88px]" />
        </div>
      </div>
    </NodeViewWrapper>
  )
}

export default AdminCommentTextComponent
