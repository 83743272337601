import { AdminProductDto } from "@runners/interface"
import { Image } from "@runners/ui"
import { NodeViewWrapper } from "@tiptap/react"
import { HeartFillIcon, StarFillIcon } from "../icons"
import { CustomNodeViewType } from "../type"

const AdminProductPreviewComponent = (props: CustomNodeViewType<AdminProductDto>) => {
  const { id, title, store_title, discount_price, discount_rate, like_count, average_grade, image_url } =
    props.node.attrs

  const handleOpenProductTab = () => {
    window.open(`/store/products/${id}`, "_blank")
  }

  return (
    <NodeViewWrapper>
      <div data-drag-handle data-node-id={id} className="my-5 flex w-full items-start gap-5 md:gap-6">
        <div
          className="size-[100px] shrink-0 bg-gray-300 hover:cursor-pointer md:size-[200px]"
          onClick={handleOpenProductTab}
        >
          {image_url && <Image src={image_url} alt={title} size="375" className="size-full object-cover" />}
        </div>

        <div className="flex flex-1 flex-col">
          <div className="flex items-center justify-between">
            <div
              className="text-14 font-bold text-gray-900 hover:cursor-pointer md:text-24"
              onClick={handleOpenProductTab}
            >
              {title}
            </div>
            <p
              className="shrink-0 text-12 font-medium text-gray-700 hover:cursor-pointer"
              onClick={handleOpenProductTab}
            >
              더보기 &gt;
            </p>
          </div>

          <div
            className="w-fit text-12 font-medium text-gray-600 hover:cursor-pointer md:text-16"
            onClick={handleOpenProductTab}
          >
            {store_title}
          </div>

          <div
            className="mt-1.5 flex w-fit items-center gap-1.5 text-12 hover:cursor-pointer md:mt-2.5 md:gap-2 md:text-20"
            onClick={handleOpenProductTab}
          >
            <span className="font-bold text-red-400">{Number(discount_rate) * 100}%</span>
            <span className="text-gray-900">
              <span className="font-bold">{discount_price?.toLocaleString()}</span>원
            </span>
          </div>

          <div className="flex items-center gap-2 text-12 font-medium md:mt-4 md:gap-5 md:text-18">
            <div className="flex items-center gap-1.5">
              <HeartFillIcon className="size-3 text-red-500 md:size-5" />
              <span>{like_count || 0}</span>
            </div>
            <div className="flex items-center gap-1.5">
              <StarFillIcon className="size-3 text-yellow-500 md:size-5" />
              <span>{average_grade || 0}</span>
            </div>
          </div>
        </div>
      </div>
    </NodeViewWrapper>
  )
}

export default AdminProductPreviewComponent
